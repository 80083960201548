<template>
  <div class="register-repeat">
    <img src="@/assets/img/cg.png" alt="cg">
    <p>注册成功</p>
    <span>温馨提示：您可前往PC端设置登录密码、修改绑定手机号等</span>
    <van-button class="btn-border-red" block  @click="$router.push('/')">返回原页面</van-button>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.register-repeat{
    padding: 48px 36px 0;
    >img{
        width: 70px;
        display: block;
        margin: 0 auto 24px;
    }
    >p{
        font-size: 24px;
        line-height: 32px;
        color: #002626;
        text-align: center;
        margin-bottom: 12px;
    }
    >span{
        display: block;
        font-size: 14px;
        line-height: 20px;
        color: #999999;
        text-align: center;
        margin-bottom: 48px;
    }
}
</style>